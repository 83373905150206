import m from 'mithril';

let titleMC = {
  view: function(vnode) {
    return(m("b", {}, "5DBRP"));
  }
};

let buildMC = {
  view: function(vnode) {
    // BUILD_TIMESTAMP not so relevant now that SHA is available
    // let tsComponent = m("div", {}, `Build ts: ${BUILD_TIMESTAMP}`);
    // BUILD_REF substituted at Vite build-time
    let shaComponent = m("div", {}, `Build SHA: ${BUILD_REF}`);
    let result = m("div", {}, [
       // tsComponent,
       shaComponent
      ]);
    return(result);
  }
};

let linksMC = {
  view: function(vnode) {
    let page1 = m("a", {href: "./#!/add"}, "add");
    let page2 = m("a", {href: "./#!/admin"}, "admin");
    let page3 = m("a", {href: "./#!/beta"}, "BETA");
    
    let result = m("div", {}, [
      page1,
      "   ",
      page2,
      "   ",
      page3,
    ]);
    return(result);
  }
};

let preambleMC = {
  view: function(vnode) {
    let result = m("div", {
        class: "header",
        class: "allRow",
      }, [
        titleMC,
        buildMC,
        linksMC,
      ].map(x => m(x, vnode.attrs)));
    return(result);
  }
}

export { preambleMC };