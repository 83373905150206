import m from 'mithril';
import { TasksAPITrampoline4 } from './apiHelpers.js';
import { allWeeksV2 } from './allWeeks.js';

// to my surprise, let resultsDOM = document.getElementById('apiResults')
// isn't usable within the splashHelper functions and I don't know why
// but implementing resultsDOM as a function works as desired
function resultsDOM() {
    return(document.getElementById('apiResults'));
}

async function add5DBRP5() {
    // add more than 1 week
    // too tired to chase down the pryamid of doom
    // prioritise reducing developer cognitive burden
    // try with async/await for expanded scope
    // even though async/await obscures how promises work
  
  /* generated weekN variables with copy/paste of 5dbrp PDF and sed:
  
  cat 5*txt | sed -E 's/� (.*)$/[{ \1 }],/g;     # wrap first and last reading of each day in start and end brackets and braces
     s/;/}, {/g;                                 # add start and end braces in between readings
     s/Week ([0-9]+)/];\r\nlet week\1 = [/g      # add JS declaration
     s/,/,\r\n/g                                 # put each reading on separate line
     s/[{] /{/g;                                                  # get rid of leading space in reading
     s/ [}]/}/g;                                                  # get rid of trailing space in reading
     s/\{([^}]+)\}/{ reading: "\1", startTS: "", endTS: "" }/g;   # add all JSON fields
     '                                           # end-of-invocation
  
  */
  
  // this is different from add5DBRP4 by creating 52 week-based entries
  
    console.log(`addQ1: START`);
  
    // create new 5DBRP tasklist
    let newTaskListResp = await TasksAPITrampoline4({
      method: "POST",
      url: "https://tasks.googleapis.com/tasks/v1/users/@me/lists",
      headers: "FYI: ADDED IN callTasksAPI",
      body: {
        title: `5DBRP: ${new Date().toISOString()}`
      }
    });
    console.log(`newTaskListResp: ${JSON.stringify(newTaskListResp)}`);
  
    let weeksResps = await Promise.all(
      allWeeksV2().map((weeksReqs, weekN) => {
        // create weekly tasks
        return(TasksAPITrampoline4({
          method: "POST",
          url: `https://tasks.googleapis.com/tasks/v1/lists/${newTaskListResp.id}/tasks`,
          headers: "FYI: ADDED IN callTasksAPI",
          body: {
           title: `week ${weekN + 1}`,
           notes: JSON.stringify(weeksReqs)
          }}))}));
    console.log(`weeksResps: ${JSON.stringify(weeksResps)}`);
    console.log(`addQ1: END`);
  }

  export { add5DBRP5 };