function allWeeksV2() {
    let week1 = {
      day1: [{ reading: "Genesis 1-2", startTS: "", endTS: "" },
  
      { reading: "Psalm 19", startTS: "", endTS: "" },
      { reading: "Mark 1", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Gen 3-5", startTS: "", endTS: "" },
      { reading: "Mark 2", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Gen 6-8", startTS: "", endTS: "" },
      { reading: "Psalm 104", startTS: "", endTS: "" },
      { reading: "Mark 3", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Gen 9-11", startTS: "", endTS: "" },
      { reading: "Mark 4", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Gen 12-15", startTS: "", endTS: "" },
      { reading: "Psalm 148", startTS: "", endTS: "" },
      { reading: "Mark 5", startTS: "", endTS: "" }],
      
    };
    let week2 = {
      day1: [{ reading: "Genesis 16-18", startTS: "", endTS: "" },
     { reading: "Mark 6", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Gen 19-20", startTS: "", endTS: "" },
     { reading: "Psalm 1", startTS: "", endTS: "" },
     { reading: "Mark 7", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Gen 21-23", startTS: "", endTS: "" },
     { reading: "Psalm 107", startTS: "", endTS: "" },
     { reading: "Mark 8", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Gen 24-25", startTS: "", endTS: "" },
     { reading: "Psalm 4", startTS: "", endTS: "" },
     { reading: "Mark 9", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Gen 26-27", startTS: "", endTS: "" },
     { reading: "Mark 10", startTS: "", endTS: "" }],
    
    };
    let week3 = {
      day1: [{ reading: "Genesis 28-29", startTS: "", endTS: "" },
     { reading: "Mark 11", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Gen 30-31", startTS: "", endTS: "" },
     { reading: "Psalm 11", startTS: "", endTS: "" },
     { reading: "Mark 12", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Gen 32-34", startTS: "", endTS: "" },
     { reading: "Psalm 145", startTS: "", endTS: "" },
     { reading: "Mark 13", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Gen 35-37", startTS: "", endTS: "" },
     { reading: "Psalm 12", startTS: "", endTS: "" },
     { reading: "Mark 14", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Gen 38-40", startTS: "", endTS: "" },
     { reading: "Mark 15", startTS: "", endTS: "" }],
    
    };
    let week4 = {
      day1: [{ reading: "Genesis 41-42", startTS: "", endTS: "" },
     { reading: "Mark 16", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Gen 43-44", startTS: "", endTS: "" },
     { reading: "Psalm 24", startTS: "", endTS: "" },
     { reading: "Galatians 1", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Gen 45-46", startTS: "", endTS: "" },
     { reading: "Psalm 108", startTS: "", endTS: "" },
     { reading: "Gal 2", startTS: "", endTS: "" }],
    
  
      day4: [{ reading: "Gen 47-48", startTS: "", endTS: "" },
     { reading: "Psalm 25", startTS: "", endTS: "" },
     { reading: "Gal 3", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Gen 49-50", startTS: "", endTS: "" },
     { reading: "Gal 4", startTS: "", endTS: "" }],
    
    };
    let week5 = {
      day1: [{ reading: "Exodus 1-3", startTS: "", endTS: "" },
     { reading: "Gal 5", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Exodus 4-6", startTS: "", endTS: "" },
     { reading: "Gal 6", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Exodus 7-9", startTS: "", endTS: "" },
     { reading: "Psalm 105", startTS: "", endTS: "" },
     { reading: "Ephesians 1", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Exodus 10-12", startTS: "", endTS: "" },
     { reading: "Eph 2", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Exodus 13-15", startTS: "", endTS: "" },
     { reading: "Psalm 114", startTS: "", endTS: "" },
     { reading: "Eph 3", startTS: "", endTS: "" }],
    
    };
    let week6 = {
      day1: [{ reading: "Exodus 16-18", startTS: "", endTS: "" },
     { reading: "Eph 4", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Exodus 19-21", startTS: "", endTS: "" },
     { reading: "Psalm 33", startTS: "", endTS: "" },
     { reading: "Eph 5", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Exodus 22-24", startTS: "", endTS: "" },
     { reading: "Psalm 109", startTS: "", endTS: "" },
     { reading: "Eph 6", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Exodus 25-27", startTS: "", endTS: "" },
     { reading: "Psalm 90", startTS: "", endTS: "" },
     { reading: "Philippians 1", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Exodus 28-31", startTS: "", endTS: "" },
     { reading: "Phil 2", startTS: "", endTS: "" }],
    
    };
    let week7 = {
      day1: [{ reading: "Exodus 32-34", startTS: "", endTS: "" },
     { reading: "Philippians 3", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Exodus 35-37", startTS: "", endTS: "" },
     { reading: "Psalm 26", startTS: "", endTS: "" },
     { reading: "Phil 4", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Exodus 38-40", startTS: "", endTS: "" },
     { reading: "Hebrews 1", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Leviticus 1-3", startTS: "", endTS: "" },
     { reading: "Psalm 27", startTS: "", endTS: "" },
     { reading: "Heb 2", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Lev 4-7", startTS: "", endTS: "" },
     { reading: "Heb 3", startTS: "", endTS: "" }],
    
    };
    let week8 = {
      day1: [{ reading: "Leviticus 8-11", startTS: "", endTS: "" },
     { reading: "Ps 110", startTS: "", endTS: "" },
     { reading: "Hebrews 4", startTS: "", endTS: "" }],
  
    
      day2: [{ reading: "Lev 12-14", startTS: "", endTS: "" },
     { reading: "Psalm 111", startTS: "", endTS: "" },
     { reading: "Heb 5", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Lev 15-18", startTS: "", endTS: "" },
     { reading: "Psalm 31", startTS: "", endTS: "" },
     { reading: "Heb 6", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Lev 19-20", startTS: "", endTS: "" },
     { reading: "Heb 7", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Lev 21-23", startTS: "", endTS: "" },
     { reading: "Heb 8", startTS: "", endTS: "" }],
    
    };
    let week9 = {
      day1: [{ reading: "Leviticus 24-25", startTS: "", endTS: "" },
     { reading: "Psalm 81", startTS: "", endTS: "" },
     { reading: "Hebrews 9", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Lev 26-27", startTS: "", endTS: "" },
     { reading: "Psalm 112", startTS: "", endTS: "" },
     { reading: "Heb 10", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Numbers 1-2", startTS: "", endTS: "" },
     { reading: "Psalm 64", startTS: "", endTS: "" },
     { reading: "Heb 11", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Num 3-5", startTS: "", endTS: "" },
     { reading: "Heb 12", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Num 6-7", startTS: "", endTS: "" },
     { reading: "Heb 13", startTS: "", endTS: "" }],
    
    };
    let week10 = {
      day1: [{ reading: "Numbers 8-11", startTS: "", endTS: "" },
     { reading: "Colossians 1", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Num 12-14", startTS: "", endTS: "" },
     { reading: "Psalm 28", startTS: "", endTS: "" },
     { reading: "Col 2", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Num 15-18", startTS: "", endTS: "" },
     { reading: "Psalm 113", startTS: "", endTS: "" },
     { reading: "Col 3", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Num 19-21", startTS: "", endTS: "" },
     { reading: "Col 4", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Num 22-25", startTS: "", endTS: "" },
     { reading: "Luke 1", startTS: "", endTS: "" }],
    
    };
    let week11 = {
      day1: [{ reading: "Numbers 26-29", startTS: "", endTS: "" },
     { reading: "Luke 2", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Num 30-33", startTS: "", endTS: "" },
     { reading: "Psalm 35", startTS: "", endTS: "" },
     { reading: "Luke 3", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Num 34-36", startTS: "", endTS: "" },
     { reading: "Luke 4", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Deuteronomy 1-3", startTS: "", endTS: "" },
     { reading: "Psalm 36", startTS: "", endTS: "" },
     { reading: "Luke 5", startTS: "", endTS: "" }],
    
  
      day5: [{ reading: "Deut 4-5", startTS: "", endTS: "" },
     { reading: "Luke 6", startTS: "", endTS: "" }],
    
    };
    let week12 = {
      day1: [{ reading: "Deuteronomy 6-9", startTS: "", endTS: "" },
     { reading: "Luke 7", startTS: "", endTS: "" }],
    
      day2: [{ reading: "Deut 10-14", startTS: "", endTS: "" },
     { reading: "Psalm 5", startTS: "", endTS: "" },
     { reading: "Luke 8", startTS: "", endTS: "" }],
    
      day3: [{ reading: "Deut 15-18", startTS: "", endTS: "" },
     { reading: "Psalm 115", startTS: "", endTS: "" },
     { reading: "Luke 9", startTS: "", endTS: "" }],
    
      day4: [{ reading: "Deut 19-22", startTS: "", endTS: "" },
     { reading: "Psalm 6", startTS: "", endTS: "" },
     { reading: "Luke 10", startTS: "", endTS: "" }],
    
      day5: [{ reading: "Deut 23-26", startTS: "", endTS: "" },
     { reading: "Luke 11", startTS: "", endTS: "" }],
    
    };
    let week13 = {
      day1: [{ reading: "Deut 27-31", startTS: "", endTS: "" },
      { reading: "Luke 12", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Deut 32-34", startTS: "", endTS: "" },
      { reading: "Psalm 13", startTS: "", endTS: "" },
      { reading: "Luke 13", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Joshua 1-4", startTS: "", endTS: "" },
      { reading: "Psalm 143", startTS: "", endTS: "" },
      { reading: "Luke 14", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Joshua 5-8", startTS: "", endTS: "" },
      { reading: "Psalm 14", startTS: "", endTS: "" },
      { reading: "Luke 15", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Joshua 9-13", startTS: "", endTS: "" },
      { reading: "Luke 16", startTS: "", endTS: "" }],
    };
          
    let week14 = {
      day1: [{ reading: "Joshua 14-17", startTS: "", endTS: "" },
       { reading: "Luke 17", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Joshua 18-21", startTS: "", endTS: "" },
       { reading: "Psalm 15", startTS: "", endTS: "" },
       { reading: "Luke 18", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Joshua 22-24", startTS: "", endTS: "" },
       { reading: "Psalm 116", startTS: "", endTS: "" },
       { reading: "Luke 19", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Judges 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 16", startTS: "", endTS: "" },
       { reading: "Luke 20", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Judges 4-6", startTS: "", endTS: "" },
  
       { reading: "Luke 21", startTS: "", endTS: "" }],
      
      };
      let week15 = {
      day1: [{ reading: "Judges 7-8", startTS: "", endTS: "" },
       { reading: "Luke 22", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Judges 9-11", startTS: "", endTS: "" },
       { reading: "Psalm 17", startTS: "", endTS: "" },
       { reading: "Luke 23", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Judges 12-16", startTS: "", endTS: "" },
       { reading: "Psalm 146", startTS: "", endTS: "" },
       { reading: "Luke 24", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Judges 17-18", startTS: "", endTS: "" },
       { reading: "Psalm 21", startTS: "", endTS: "" },
       { reading: "Acts 1", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Judges 19-21", startTS: "", endTS: "" },
       { reading: "Acts 2", startTS: "", endTS: "" }],
      
      };
      let week16 = {
      day1: [{ reading: "Ruth 1-2", startTS: "", endTS: "" },
       { reading: "Acts 3", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ruth 3-4", startTS: "", endTS: "" },
       { reading: "Psalm 37", startTS: "", endTS: "" },
       { reading: "Acts 4", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1 Samuel 1-2", startTS: "", endTS: "" },
       { reading: "Psalm 120", startTS: "", endTS: "" },
       { reading: "Acts 5", startTS: "", endTS: "" }],
      
      day4: [{ reading: "1 Sam 3-5", startTS: "", endTS: "" },
       { reading: "Psalm 23", startTS: "", endTS: "" },
       { reading: "Acts 6", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1 Sam 6-8", startTS: "", endTS: "" },
       { reading: "Acts 7", startTS: "", endTS: "" }],
      
      };
      let week17 = {
      day1: [{ reading: "1 Samuel 9-10", startTS: "", endTS: "" },
       { reading: "Acts 8", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1 Sam 11-13", startTS: "", endTS: "" },
       { reading: "Psalm 38", startTS: "", endTS: "" },
       { reading: "Acts 9", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1 Sam 14", startTS: "", endTS: "" },
       { reading: "Psalm 124", startTS: "", endTS: "" },
       { reading: "Acts 10", startTS: "", endTS: "" }],
      
      day4: [{ reading: "1Sam15-16", startTS: "", endTS: "" },
       { reading: "1Chr1", startTS: "", endTS: "" },
       { reading: "Ps39", startTS: "", endTS: "" },
       { reading: "Acts11", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1Sam17", startTS: "", endTS: "" },
       { reading: "1Chr2", startTS: "", endTS: "" },
       { reading: "Acts12", startTS: "", endTS: "" }],
      
      };
      let week18 = {
      day1: [{ reading: "1Sam18-19", startTS: "", endTS: "" },
         { reading: "1Chr3", startTS: "", endTS: "" },
         { reading: "Ps59", startTS: "", endTS: "" },
         { reading: "Acts13", startTS: "", endTS: "" }],
  
        
      day2: [{ reading: "1Sa20", startTS: "", endTS: "" },
         { reading: "1Chr4", startTS: "", endTS: "" },
         { reading: "Ps56,57,142", startTS: "", endTS: "" },
         { reading: "Acts14 ", startTS: "", endTS: "" }],
        
      day3: [{ reading: "1Sam21-22", startTS: "", endTS: "" },
         { reading: "1Chr5", startTS: "", endTS: "" },
         { reading: "Ps52", startTS: "", endTS: "" },
         { reading: "Acts15", startTS: "", endTS: "" }],
        
      day4: [{ reading: "1Sam23-24", startTS: "", endTS: "" },
         { reading: "1Chr6", startTS: "", endTS: "" },
         { reading: "Ps54", startTS: "", endTS: "" },
         { reading: "Acts16 ", startTS: "", endTS: "" }],
        
      day5: [{ reading: "1Sam25", startTS: "", endTS: "" },
         { reading: "1Chr7", startTS: "", endTS: "" },
         { reading: "Acts17", startTS: "", endTS: "" }],
        
        };
      let week19 = {
      day1: [{ reading: "1Sam26-27", startTS: "", endTS: "" },
       { reading: "1Chr8", startTS: "", endTS: "" },
       { reading: "Acts18", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1Sam28-29", startTS: "", endTS: "" },
       { reading: "1Chr9", startTS: "", endTS: "" },
       { reading: "Acts19", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1 Sam 30-31", startTS: "", endTS: "" },
       { reading: "1 Chr 10", startTS: "", endTS: "" },
       { reading: "Acts 20", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2Sam1-2", startTS: "", endTS: "" },
       { reading: "1Chr11", startTS: "", endTS: "" },
       { reading: "Ps96,106", startTS: "", endTS: "" },
       { reading: "Acts21 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Sam3-5", startTS: "", endTS: "" },
       { reading: "1Chr12", startTS: "", endTS: "" },
       { reading: "Ps122", startTS: "", endTS: "" },
       { reading: "Acts22", startTS: "", endTS: "" }],
      
      };
      let week20 = {
      day1: [{ reading: "2Sam6", startTS: "", endTS: "" },
       { reading: "1Chr13", startTS: "", endTS: "" },
       { reading: "Psalm60", startTS: "", endTS: "" },
       { reading: "Acts23", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1 Chron 14-16", startTS: "", endTS: "" },
       { reading: "Acts 24", startTS: "", endTS: "" }],
      
      day3: [{ reading: "2Sam7-8", startTS: "", endTS: "" },
       { reading: "1Chr17", startTS: "", endTS: "" },
       { reading: "Ps132", startTS: "", endTS: "" },
       { reading: "Acts25", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2 Sam 9-10", startTS: "", endTS: "" },
       { reading: "1 Chr 18-19", startTS: "", endTS: "" },
       { reading: "Ps 89", startTS: "", endTS: "" },
       { reading: "Acts 26", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Sam11-12", startTS: "", endTS: "" },
       { reading: "1Chr20", startTS: "", endTS: "" },
       { reading: "Ps51,32", startTS: "", endTS: "" },
       { reading: "Acts27", startTS: "", endTS: "" }],
      
       };
  
      let week21 = {
      day1: [{ reading: "2 Samuel 13-14", startTS: "", endTS: "" },
       { reading: "Acts 28", startTS: "", endTS: "" }],
      
      day2: [{ reading: "2 Sam 15-17", startTS: "", endTS: "" },
       { reading: "Psalms 3,63", startTS: "", endTS: "" },
       { reading: "Romans 1", startTS: "", endTS: "" }],
      
      day3: [{ reading: "2 Sam 18-20", startTS: "", endTS: "" },
       { reading: "Psalm 34", startTS: "", endTS: "" },
       { reading: "Romans 2", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2 Sam 21-23", startTS: "", endTS: "" },
       { reading: "Psalm 18", startTS: "", endTS: "" },
       { reading: "Romans 3", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Sam24", startTS: "", endTS: "" },
       { reading: "1Chr21", startTS: "", endTS: "" },
       { reading: "Romans4", startTS: "", endTS: "" }],
      
      };
      let week22 = {
      day1: [{ reading: "1 Chron 22-25", startTS: "", endTS: "" },
       { reading: "Psalm 78", startTS: "", endTS: "" },
       { reading: "Romans 5", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1 Kings 1", startTS: "", endTS: "" },
       { reading: "1 Chr 26-28", startTS: "", endTS: "" },
       { reading: "Romans 6", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1Kings2", startTS: "", endTS: "" },
       { reading: "1Chr29", startTS: "", endTS: "" },
       { reading: "Romans7", startTS: "", endTS: "" }],
      
      day4: [{ reading: "1Kings3", startTS: "", endTS: "" },
       { reading: "2Chr1", startTS: "", endTS: "" },
       { reading: "Ps42", startTS: "", endTS: "" },
       { reading: "Romans8", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1 Kings 4", startTS: "", endTS: "" },
       { reading: "Prov 1-2", startTS: "", endTS: "" },
       { reading: "Psalm 43", startTS: "", endTS: "" },
       { reading: "Romans 9 ", startTS: "", endTS: "" }],
      
      };
      let week23 = {
      day1: [{ reading: "Proverbs 3-5", startTS: "", endTS: "" },
       { reading: "Romans 10", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Proverbs 6-7", startTS: "", endTS: "" },
       { reading: "Psalm 7", startTS: "", endTS: "" },
       { reading: "Romans 11", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Proverbs 8-10", startTS: "", endTS: "" },
       { reading: "Psalm 144", startTS: "", endTS: "" },
       { reading: "Romans 12", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Proverbs 11-13", startTS: "", endTS: "" },
       { reading: "Psalm 8", startTS: "", endTS: "" },
       { reading: "Romans 13", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Proverbs 14-15", startTS: "", endTS: "" },
       { reading: "Romans 14", startTS: "", endTS: "" }],
      
      };
      let week24 = {
      day1: [{ reading: "Proverbs 16-18", startTS: "", endTS: "" },
       { reading: "Romans 15", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Proverbs 19-21", startTS: "", endTS: "" },
  
       { reading: "Ps 40", startTS: "", endTS: "" },
       { reading: "Romans 16", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Proverbs 22-23", startTS: "", endTS: "" },
       { reading: "Ps 117", startTS: "", endTS: "" },
       { reading: "1 Thess 1", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Proverbs 24-25", startTS: "", endTS: "" },
       { reading: "Ps 41", startTS: "", endTS: "" },
       { reading: "1 Thess 2", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Proverbs 26-28", startTS: "", endTS: "" },
       { reading: "1 Thess 3", startTS: "", endTS: "" }],
      
      };
      let week25 = {
      day1: [{ reading: "Proverbs 29-31", startTS: "", endTS: "" },
       { reading: "1 Thess 4", startTS: "", endTS: "" }],
      
      day2: [{ reading: "SongofSol1-3", startTS: "", endTS: "" },
       { reading: "Ps72", startTS: "", endTS: "" },
       { reading: "1Thess5", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Song of Sol 4-6", startTS: "", endTS: "" },
       { reading: "2 Thess 1", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Song of Sol 7-8", startTS: "", endTS: "" },
       { reading: "Psalm 127", startTS: "", endTS: "" },
       { reading: "2 Thess 2", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1Kings5", startTS: "", endTS: "" },
       { reading: "2Chr2", startTS: "", endTS: "" },
       { reading: "2Thess3", startTS: "", endTS: "" }],
      
      };
      let week26 = {
      day1: [{ reading: "1Kings6", startTS: "", endTS: "" },
       { reading: "2Chron3", startTS: "", endTS: "" },
       { reading: "1Timothy1", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1Kings7", startTS: "", endTS: "" },
       { reading: "2Chr4", startTS: "", endTS: "" },
       { reading: "Psalm44", startTS: "", endTS: "" },
       { reading: "1Tim2", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1Kings8", startTS: "", endTS: "" },
       { reading: "Psalm30", startTS: "", endTS: "" },
       { reading: "1Tim3", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2Chr5-7", startTS: "", endTS: "" },
       { reading: "Psalm121", startTS: "", endTS: "" },
       { reading: "1Tim4", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1Kings9", startTS: "", endTS: "" },
       { reading: "2Chr8", startTS: "", endTS: "" },
       { reading: "1Tim5", startTS: "", endTS: "" }],
      
      };
    let week27 = {
  
      day1: [{ reading: "1Kings10-11", startTS: "", endTS: "" },
       { reading: "2Chr9", startTS: "", endTS: "" },
       { reading: "1Tim6", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ecclesiastes 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 45", startTS: "", endTS: "" },
       { reading: "2 Tim 1", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Eccl 4-6", startTS: "", endTS: "" },
       { reading: "Psalm 125", startTS: "", endTS: "" },
       { reading: "2 Tim 2", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Eccl 7-9", startTS: "", endTS: "" },
       { reading: "Psalm 46", startTS: "", endTS: "" },
       { reading: "2 Tim 3", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Eccl 10-12", startTS: "", endTS: "" },
       { reading: "2 Tim 4", startTS: "", endTS: "" }],
      
      };
      let week28 = {
      day1: [{ reading: "1 Kings 12", startTS: "", endTS: "" },
       { reading: "2 Chr 10-11", startTS: "", endTS: "" },
       { reading: "Titus 1", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1 Kings 13-14", startTS: "", endTS: "" },
       { reading: "2 Chr 12", startTS: "", endTS: "" },
       { reading: "Ps 47", startTS: "", endTS: "" },
       { reading: "Titus 2", startTS: "", endTS: "" }],
      
      day3: [{ reading: "1 Kings 15", startTS: "", endTS: "" },
       { reading: "2 Chr 13-14", startTS: "", endTS: "" },
       { reading: "Titus 3", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2 Chr 15-16", startTS: "", endTS: "" },
       { reading: "1 Kings 16", startTS: "", endTS: "" },
       { reading: "Philemon", startTS: "", endTS: "" }],
      
      day5: [{ reading: "1 Kings 17-18", startTS: "", endTS: "" },
       { reading: "Psalm 119", startTS: "", endTS: "" },
       { reading: "Jude", startTS: "", endTS: "" }],
      
      };
      let week29 = {
      day1: [{ reading: "1Kin19-21", startTS: "", endTS: "" },
       { reading: "2Chr17", startTS: "", endTS: "" },
       { reading: "Ps129", startTS: "", endTS: "" },
       { reading: "Matt1", startTS: "", endTS: "" }],
      
      day2: [{ reading: "1Kings22", startTS: "", endTS: "" },
       { reading: "2Chr18", startTS: "", endTS: "" },
       { reading: "Matt2", startTS: "", endTS: "" }],
      
      day3: [{ reading: "2 Chr 19-20", startTS: "", endTS: "" },
       { reading: "2 Kings 1", startTS: "", endTS: "" },
       { reading: "Psalm 20", startTS: "", endTS: "" },
       { reading: "Matt 3 ", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2 Kings 2-3", startTS: "", endTS: "" },
       { reading: "Psalm 48", startTS: "", endTS: "" },
       { reading: "Matt 4", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2 Kings 4-6", startTS: "", endTS: "" },
       { reading: "Matt 5", startTS: "", endTS: "" }],
      
      };
      let week30 = {
      day1: [{ reading: "2Kings7-8", startTS: "", endTS: "" },
       { reading: "2Chr21", startTS: "", endTS: "" },
       { reading: "Matt6", startTS: "", endTS: "" }],
  
      
      day2: [{ reading: "2 Kings 9-10", startTS: "", endTS: "" },
       { reading: "Psalm 49", startTS: "", endTS: "" },
       { reading: "Matt 7", startTS: "", endTS: "" }],
      
      day3: [{ reading: "2 Chr 22-23", startTS: "", endTS: "" },
       { reading: "2 Kings 11", startTS: "", endTS: "" },
       { reading: "Ps 131", startTS: "", endTS: "" },
       { reading: "Matt 8 ", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2Chr24", startTS: "", endTS: "" },
       { reading: "2Kings12", startTS: "", endTS: "" },
       { reading: "Psalm50", startTS: "", endTS: "" },
       { reading: "Matt9 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Joel", startTS: "", endTS: "" },
       { reading: "Matt 10", startTS: "", endTS: "" }],
      
      };
      let week31 = {
      day1: [{ reading: "Jonah", startTS: "", endTS: "" },
       { reading: "Matthew 11", startTS: "", endTS: "" }],
      
      day2: [{ reading: "2 Kings 13-14", startTS: "", endTS: "" },
       { reading: "2 Chr 25", startTS: "", endTS: "" },
       { reading: "Ps 53", startTS: "", endTS: "" },
       { reading: "Matt 12 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Amos 1-3", startTS: "", endTS: "" },
       { reading: "Matt 13", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Amos 4-6", startTS: "", endTS: "" },
       { reading: "Psalm 55", startTS: "", endTS: "" },
       { reading: "Matt 14", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Amos 7-9", startTS: "", endTS: "" },
       { reading: "Matt 15", startTS: "", endTS: "" }],
      
      };
      let week32 = {
      day1: [{ reading: "Hosea 1-3", startTS: "", endTS: "" },
       { reading: "Matthew 16", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Hosea 4-6", startTS: "", endTS: "" },
       { reading: "Psalm 58", startTS: "", endTS: "" },
       { reading: "Matt 17", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Hosea 7-10", startTS: "", endTS: "" },
       { reading: "Matt 18", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Hosea 11-13", startTS: "", endTS: "" },
       { reading: "Matt 19", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Hosea 14", startTS: "", endTS: "" },
       { reading: "2 Chr 26-27", startTS: "", endTS: "" },
       { reading: "Ps 61", startTS: "", endTS: "" },
       { reading: "Matt 20 ", startTS: "", endTS: "" }],
      
      };
      let week33 = {
      day1: [{ reading: "2 Kings 15-16", startTS: "", endTS: "" },
       { reading: "Matthew 21", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Isaiah 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 9", startTS: "", endTS: "" },
       { reading: "Matt 22", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Isaiah 4-6", startTS: "", endTS: "" },
       { reading: "Matt 23", startTS: "", endTS: "" }],
      
  
      day4: [{ reading: "Micah 1-4", startTS: "", endTS: "" },
       { reading: "Psalm 10", startTS: "", endTS: "" },
       { reading: "Matt 24", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Micah 5-7", startTS: "", endTS: "" },
       { reading: "Matt 25", startTS: "", endTS: "" }],
      
      };
      let week34 = {
      day1: [{ reading: "Isaiah 7-10", startTS: "", endTS: "" },
       { reading: "Psalm 22", startTS: "", endTS: "" },
       { reading: "Matt 26", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Isa 11-13", startTS: "", endTS: "" },
       { reading: "Psalm 118", startTS: "", endTS: "" },
       { reading: "Matt 27", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Isa 14-16", startTS: "", endTS: "" },
       { reading: "Matt 28", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Isa 17-19", startTS: "", endTS: "" },
       { reading: "Psalm 62", startTS: "", endTS: "" },
       { reading: "1 Corinthians 1 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Isa20-22", startTS: "", endTS: "" },
       { reading: "1Cor2", startTS: "", endTS: "" }],
      
      };
      let week35 = {
      day1: [{ reading: "Isaiah 23-25", startTS: "", endTS: "" },
       { reading: "1 Corinthians 3", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Isa 26-29", startTS: "", endTS: "" },
       { reading: "Psalm 65", startTS: "", endTS: "" },
       { reading: "1 Cor 4 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Isa30-32", startTS: "", endTS: "" },
       { reading: "1Cor5", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Isa33-35", startTS: "", endTS: "" },
       { reading: "1Cor6", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Chr28", startTS: "", endTS: "" },
       { reading: "2Kings17", startTS: "", endTS: "" },
       { reading: "Psalm66", startTS: "", endTS: "" },
       { reading: "1Cor7 ", startTS: "", endTS: "" }],
      
      };
      let week36 = {
      day1: [{ reading: "2Chr29-31", startTS: "", endTS: "" },
       { reading: "1Cor8", startTS: "", endTS: "" }],
      
      day2: [{ reading: "2Kings18-19", startTS: "", endTS: "" },
       { reading: "2Chr32", startTS: "", endTS: "" },
       { reading: "Ps67", startTS: "", endTS: "" },
       { reading: "1Cor9", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Isa 36-37", startTS: "", endTS: "" },
       { reading: "Psalm 123", startTS: "", endTS: "" },
       { reading: "1 Cor 10 ", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2Kings20", startTS: "", endTS: "" },
       { reading: "Isa38-40", startTS: "", endTS: "" },
       { reading: "Ps68", startTS: "", endTS: "" },
       { reading: "1Cor11", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Isa 41-44", startTS: "", endTS: "" },
       { reading: "1 Cor 12", startTS: "", endTS: "" }],
      
      };
  
      let week37 = {
      day1: [{ reading: "Isa 45-48", startTS: "", endTS: "" },
       { reading: "1 Cor 13", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Isa 49-52", startTS: "", endTS: "" },
       { reading: "Psalm 69", startTS: "", endTS: "" },
       { reading: "1 Cor 14", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Isa 53-55", startTS: "", endTS: "" },
       { reading: "Psalm 128", startTS: "", endTS: "" },
       { reading: "1 Cor 15", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Isa 56-59", startTS: "", endTS: "" },
       { reading: "Psalm 70", startTS: "", endTS: "" },
       { reading: "1 Cor 16", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Is 60-63", startTS: "", endTS: "" },
       { reading: "2 Cor 1", startTS: "", endTS: "" }],
      
      };
      let week38 = {
      day1: [{ reading: "Isa 64-66", startTS: "", endTS: "" },
       { reading: "2 Cor 2", startTS: "", endTS: "" }],
      
      day2: [{ reading: "2Kings21", startTS: "", endTS: "" },
       { reading: "2Chr33", startTS: "", endTS: "" },
       { reading: "Ps71", startTS: "", endTS: "" },
       { reading: "2Cor3 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Nahum", startTS: "", endTS: "" },
       { reading: "Psalm 149", startTS: "", endTS: "" },
       { reading: "2 Cor 4", startTS: "", endTS: "" }],
      
      day4: [{ reading: "2 Kings 22-23", startTS: "", endTS: "" },
       { reading: "Psalm 73", startTS: "", endTS: "" },
       { reading: "2 Cor 5", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Chr34-35", startTS: "", endTS: "" },
       { reading: "2Cor6", startTS: "", endTS: "" }],
      
      };
      let week39 = {
      day1: [{ reading: "Habakkuk", startTS: "", endTS: "" },
       { reading: "2 Cor 7", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Zephaniah", startTS: "", endTS: "" },
       { reading: "Psalm 74", startTS: "", endTS: "" },
       { reading: "2 Cor 8", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Jeremiah 1-4", startTS: "", endTS: "" },
       { reading: "Psalm 130", startTS: "", endTS: "" },
       { reading: "2 Cor 9", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Jer 5-7", startTS: "", endTS: "" },
       { reading: "Psalm 75", startTS: "", endTS: "" },
       { reading: "2 Cor 10", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Jer 8-10", startTS: "", endTS: "" },
       { reading: "2 Cor 11", startTS: "", endTS: "" }],
      
      };
    let week40 = {
      day1: [{ reading: "Jer 11-13", startTS: "", endTS: "" },
       { reading: "2 Cor 12", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Jer 14-16", startTS: "", endTS: "" },
       { reading: "Psalm 76", startTS: "", endTS: "" },
       { reading: "2 Cor 13", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Jer 17-20", startTS: "", endTS: "" },
       { reading: "James 1", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Jer 22, 23, 26", startTS: "", endTS: "" },
       { reading: "Psalm 77", startTS: "", endTS: "" },
       { reading: "James 2 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Jer 25, 35, 36, 45", startTS: "", endTS: "" },
       { reading: "Ps 133", startTS: "", endTS: "" },
       { reading: "James 3 ", startTS: "", endTS: "" }],
      
      };
      let week41 = {
      day1: [{ reading: "Jer 27, 28, 29, 24", startTS: "", endTS: "" },
       { reading: "James 4", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Jer 37, 21, 34", startTS: "", endTS: "" },
       { reading: "Psalm 79", startTS: "", endTS: "" },
       { reading: "James 5 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Jer 30-33", startTS: "", endTS: "" },
       { reading: "1 Peter 1", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Jer38, 39, 52", startTS: "", endTS: "" },
       { reading: "1Pet2", startTS: "", endTS: "" }],
      
      day5: [{ reading: "2Kin24-25", startTS: "", endTS: "" },
       { reading: "2Chr36", startTS: "", endTS: "" },
       { reading: "Ps126", startTS: "", endTS: "" },
       { reading: "1Pt3 ", startTS: "", endTS: "" }],
      
      };
      let week42 = {
      day1: [{ reading: "Lamentations", startTS: "", endTS: "" },
       { reading: "Psalm 137", startTS: "", endTS: "" },
       { reading: "1 Peter 4 ", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Obadiah", startTS: "", endTS: "" },
       { reading: "Jer 40-42", startTS: "", endTS: "" },
       { reading: "Ps 147", startTS: "", endTS: "" },
       { reading: "1 Pet 5 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Jeremiah 43, 44, 46", startTS: "", endTS: "" },
       { reading: "2 Peter 1", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Jer 47, 48, 49", startTS: "", endTS: "" },
       { reading: "Psalm 80", startTS: "", endTS: "" },
       { reading: "2 Peter 2 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Jer 50-51", startTS: "", endTS: "" },
       { reading: "2 Peter 3", startTS: "", endTS: "" }],
      
      };
      let week43 = {
      day1: [{ reading: "Ezekiel 1-3", startTS: "", endTS: "" },
       { reading: "John 1", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ezek 4-6", startTS: "", endTS: "" },
       { reading: "Psalm 82", startTS: "", endTS: "" },
       { reading: "John 2", startTS: "", endTS: "" }],
  
      
      day3: [{ reading: "Ezek 7-9", startTS: "", endTS: "" },
       { reading: "John 3", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Ezek 10-12", startTS: "", endTS: "" },
       { reading: "Psalm 83", startTS: "", endTS: "" },
       { reading: "John 4", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Ezek 13-15", startTS: "", endTS: "" },
       { reading: "Psalm 136", startTS: "", endTS: "" },
       { reading: "John 5 ", startTS: "", endTS: "" }],
      
      };
      let week44 = {
      day1: [{ reading: "Ezekiel 16-18", startTS: "", endTS: "" },
       { reading: "John 6", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ezek 19-21", startTS: "", endTS: "" },
       { reading: "Psalm 84", startTS: "", endTS: "" },
       { reading: "John 7", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Ezek 22-24", startTS: "", endTS: "" },
       { reading: "Psalm 134", startTS: "", endTS: "" },
       { reading: "John 8", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Ezek 25-27", startTS: "", endTS: "" },
       { reading: "Psalm 85", startTS: "", endTS: "" },
       { reading: "John 9", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Ezek 28-30", startTS: "", endTS: "" },
       { reading: "John 10", startTS: "", endTS: "" }],
      
      };
      let week45 = {
      day1: [{ reading: "Ezekiel 31-33", startTS: "", endTS: "" },
       { reading: "John 11", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ezek 34-36", startTS: "", endTS: "" },
       { reading: "Psalm 86", startTS: "", endTS: "" },
       { reading: "John 12", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Ezek 37-39", startTS: "", endTS: "" },
       { reading: "Psalm 87", startTS: "", endTS: "" },
       { reading: "John 13", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Ezek 40-42", startTS: "", endTS: "" },
       { reading: "John 14", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Ezek 43-45", startTS: "", endTS: "" },
       { reading: "Psalm 135", startTS: "", endTS: "" },
       { reading: "John 15", startTS: "", endTS: "" }],
      
      };
      let week46 = {
      day1: [{ reading: "Ezekiel 46-48", startTS: "", endTS: "" },
       { reading: "John 16", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Daniel 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 88", startTS: "", endTS: "" },
       { reading: "John 17", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Daniel 4-6", startTS: "", endTS: "" },
       { reading: "John 18", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Daniel 7-9", startTS: "", endTS: "" },
       { reading: "Psalm 91", startTS: "", endTS: "" },
       { reading: "John 19", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Daniel 10-12", startTS: "", endTS: "" },
       { reading: "John 20", startTS: "", endTS: "" }],
  
      
      };
      let week47 = {
      day1: [{ reading: "Ezra 1-2", startTS: "", endTS: "" },
       { reading: "John 21", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ezra 3-4", startTS: "", endTS: "" },
       { reading: "Psalm 92", startTS: "", endTS: "" },
       { reading: "1 John 1", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Haggai", startTS: "", endTS: "" },
       { reading: "Zechariah 1", startTS: "", endTS: "" },
       { reading: "Ps 138", startTS: "", endTS: "" },
       { reading: "1 John 2 ", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Zechariah 2-5", startTS: "", endTS: "" },
       { reading: "Psalm 93", startTS: "", endTS: "" },
       { reading: "1 John 3", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Zechariah 6-8", startTS: "", endTS: "" },
       { reading: "1 John 4", startTS: "", endTS: "" }],
      
      };
      let week48 = {
      day1: [{ reading: "Zechariah 9-11", startTS: "", endTS: "" },
       { reading: "1 John 5", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Zechariah 12-14", startTS: "", endTS: "" },
       { reading: "Psalm 94", startTS: "", endTS: "" },
       { reading: "2 John", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Ezra 5-6", startTS: "", endTS: "" },
       { reading: "Psalm 95", startTS: "", endTS: "" },
       { reading: "3 John", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Esther 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 139", startTS: "", endTS: "" },
       { reading: "Revelation 1 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Esther 4-6", startTS: "", endTS: "" },
       { reading: "Revelation 2", startTS: "", endTS: "" }],
      
      };
      let week49 = {
      day1: [{ reading: "Esther 7-10", startTS: "", endTS: "" },
       { reading: "Revelation 3", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Ezra 7-10", startTS: "", endTS: "" },
       { reading: "Psalm 97", startTS: "", endTS: "" },
       { reading: "Rev 4", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Nehemiah 1-3", startTS: "", endTS: "" },
       { reading: "Rev 5", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Neh 4-6", startTS: "", endTS: "" },
       { reading: "Psalm 98", startTS: "", endTS: "" },
       { reading: "Rev 6", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Neh 7-9", startTS: "", endTS: "" },
       { reading: "Psalm 140", startTS: "", endTS: "" },
       { reading: "Rev 7", startTS: "", endTS: "" }],
      
      };
      let week50 = {
      day1: [{ reading: "Neh 10-13", startTS: "", endTS: "" },
       { reading: "Revelation 8", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Malachi", startTS: "", endTS: "" },
       { reading: "Psalm 2", startTS: "", endTS: "" },
       { reading: "Rev 9", startTS: "", endTS: "" }],
  
      
      day3: [{ reading: "Job 1-3", startTS: "", endTS: "" },
       { reading: "Psalm 29", startTS: "", endTS: "" },
       { reading: "Rev 10", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Job 4-7", startTS: "", endTS: "" },
       { reading: "Psalm 99", startTS: "", endTS: "" },
       { reading: "Rev 11", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Job 8-11", startTS: "", endTS: "" },
       { reading: "Rev 12", startTS: "", endTS: "" }],
      
      };
      let week51 = {
      day1: [{ reading: "Job 12-14", startTS: "", endTS: "" },
       { reading: "Psalm 100", startTS: "", endTS: "" },
       { reading: "Rev 13", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Job 15-17", startTS: "", endTS: "" },
       { reading: "Rev 14", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Job 18-20", startTS: "", endTS: "" },
       { reading: "Psalm 141", startTS: "", endTS: "" },
       { reading: "Rev 15", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Job 21-23", startTS: "", endTS: "" },
       { reading: "Psalm 101", startTS: "", endTS: "" },
       { reading: "Rev 16", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Job 24-27", startTS: "", endTS: "" },
       { reading: "Rev 17", startTS: "", endTS: "" }],
      
      };
      let week52 = {
      day1: [{ reading: "Job 28-30", startTS: "", endTS: "" },
       { reading: "Revelation 18", startTS: "", endTS: "" }],
      
      day2: [{ reading: "Job 31-33", startTS: "", endTS: "" },
       { reading: "Psalm 102", startTS: "", endTS: "" },
       { reading: "Rev 19 ", startTS: "", endTS: "" }],
      
      day3: [{ reading: "Job 34-36", startTS: "", endTS: "" },
       { reading: "Rev 20", startTS: "", endTS: "" }],
      
      day4: [{ reading: "Job 37-39", startTS: "", endTS: "" },
       { reading: "Psalm 103", startTS: "", endTS: "" },
       { reading: "Rev 21 ", startTS: "", endTS: "" }],
      
      day5: [{ reading: "Job 40-42", startTS: "", endTS: "" },
       { reading: "Psalm 150", startTS: "", endTS: "" },
       { reading: "Rev 22", startTS: "", endTS: "" }],
    };
  
    let result = [
      week1, week2, week3, week4, week5,
      week6, week7, week8, week9, week10,
      week11, week12, week13, week14, week15,
      week16, week17, week18, week19, week20,
      week21, week22, week23, week24, week25,
      week26, week27, week28, week29, week30,
      week31, week32, week33, week34, week35,
      week36, week37, week38, week39, week40,
      week41, week42, week43, week44, week45,
      week46, week47, week48, week49, week50,
      week51, week52,
    ];
  
    return(result);
  }

  export { allWeeksV2 };