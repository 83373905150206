import { TasksAPITrampoline4 } from './apiHelpers.js';

let latest5DBRP = 'TO BE REPLACED AND EXPORTED';
let scheduledWeek = 'TO BE REPLACED AND EXPORTED';
let scheduledDay = [];
let scheduledReading = 'TO BE REPLACED AND EXPORTED';
let urlState = {
  //
  taskListID: 'INTERNAL USE ONLY',
  taskID: 'INTERNAL USE ONLY',
  taskBody: {
    id: 'INTERNAL USE ONLY'
  },
  taskNotes: 'INTERNAL USE ONLY'
};
let putRESTReq = '';
let putRESTResp = '';
let updateNoteExtraDebug = 'INTERNAL USE ONLY';
function loadLatest5DBRP() {
  return(TasksAPITrampoline4({
      method: "GET",
      url: `https://tasks.googleapis.com/tasks/v1/users/@me/lists/?qID=${Date.now()}`,
      headers: "FYI: from showTaskLists2"
    }).then(allTaskListsResp => {
        let rawResult = [];
        // rawResult = allTaskListsResp; // // DEBUG only 
        rawResult = allTaskListsResp
          .items
          .filter(e => e.title.includes("5DBRP"))
        ;
        
        // sort by e.updated descending order and take first element
        latest5DBRP = rawResult.map(e => {
          return({
              id: e.id,
              title: e.title,
              updated: e.updated,
          });
        }).toSorted((a,b) => new Date(b.updated) - new Date(a.updated))[0];

        // return the tasklist ID for load5DBRPTasks
        urlState.taskListID = latest5DBRP.id;
        return(latest5DBRP.id);
    })
  );
}

function load5DBRPTasks(taskListID) {
  //
  let curTS = new Date().toISOString();
  let tasksP = TasksAPITrampoline4({
    method: "GET",
    url: `https://tasks.googleapis.com/tasks/v1/lists/${taskListID}/tasks?maxResults=100&qID=${Date.now()}`,
    headers: "FYI: from showTaskLists2"
  }).then(resp => {
    scheduledWeek = resp.items.toSorted((a,b) => weekNumber(a.title) - weekNumber(b.title))[0];
    urlState.taskID = scheduledWeek.id;
    urlState.taskNotes = scheduledWeek.notes;
    return(scheduledWeek);
  });
  return(tasksP);
}

function weekNumber(taskTitle) {
  // extract the week number
  let parts = taskTitle.split(' ');
  let result = parts[parts.length-1];
  return(result);
}

function calculateScheduledDay(schWkObj) {
  // didn't work at first
  let notesObj = Object.entries(schWkObj);
  let result = notesObj.filter(e => e[1].some(readingPending))[0];
  scheduledDay = result;
  return(result);
}
function readingPending(r) {
  let result = (r.startTS.length == 0) && (r.endTS.length == 0);
  return(result);
}  

function determineNextReading(scheduledDay) {
  // doesn't work at first
  // scheduledReading = scheduledDay[1].reading;
  let allReadings = scheduledDay[1];
  let remainingReadings = allReadings.filter(readingPending); 
  let nextRemainingReading = remainingReadings[0];
  scheduledReading = nextRemainingReading.reading;
  return(scheduledReading);
}

function updateNoteAndTask(dateParm) {
  // dateParm is
  /*
  {
    startTS: ???,
    endTS: ???
  }
  */
  // construct latest completed reading
  let updatedReading = dateParm;
  updatedReading.reading = scheduledReading;

  // take scheduledReading
  // in scheduledDay, replace old reading with scheduledReading
  let newScheduledReadings = scheduledDay[1].filter(
    e => e.reading.localeCompare(updatedReading.reading) != 0
  );
  newScheduledReadings.unshift(updatedReading); 
  
  // take#2: update newScheduledReadings in-place
  let newScheduledReadings2 = scheduledDay[1];
  let reading2replaceIndex = newScheduledReadings2.findIndex(
    e => e.reading.localeCompare(updatedReading.reading) == 0
  );
  newScheduledReadings2.splice(reading2replaceIndex, 1, updatedReading);


  // in scheduledWeek.notes:
  //   JSON.parse
  //   replace old scheduledDay with new scheduledDay
  //   JSON.stringify
  let newScheduledWeek = scheduledWeek;
  let newScheduledWeekNotesObj = JSON.parse(newScheduledWeek.notes);
  // newScheduledWeekNotesObj[scheduledDay[0]] = newScheduledReadings; // the old way
  newScheduledWeekNotesObj[scheduledDay[0]] = newScheduledReadings2;

  // week task admin: mark complete if pending readings
  // no longer remain
  let weekUnfinished = Object.entries(newScheduledWeekNotesObj)
    .some(e => e[1].some(readingPending));
  if (weekUnfinished) {
    // pending readings remain, week task admin is complete
  } else {
    // no more pending readings, mark this week's task complete
    console.log(`${new Date().toISOString()}: marking ${scheduledWeek.title} complete`)
    newScheduledWeek.status = 'completed';
    newScheduledWeek.completed = `${new Date().toISOString()}`;
  }
  // update new ScheduledWeek with updated note object
  newScheduledWeek.notes = JSON.stringify(newScheduledWeekNotesObj);
  // MVP saveReading dev debug
  updateNoteExtraDebug = newScheduledWeek;
  return(newScheduledWeek);
}

function saveReading(dateParm) {
  // dateParm is
  /*
  {
    startTS: ???,
    endTS: ???
  }
  */
  putRESTReq = updateNoteAndTask(dateParm);
  return(TasksAPITrampoline4({
    method: "PUT",
    url: `https://tasks.googleapis.com/tasks/v1/lists/${urlState.taskListID}/tasks/${urlState.taskID}/?qID=${Date.now()}`,
    headers: "FYI: from showTaskLists2",
    body: putRESTReq
  })
    .then(resp => {
      if(resp.completed == null) {
        // this week is not complete, no further action required
        // and pass on resp to next promise
        return(resp);
      } else {
        // week task admin: otherwise assume the task is completed, then clear tasks as well
        return(TasksAPITrampoline4({
          method: "POST",
          url: `https://tasks.googleapis.com/tasks/v1/lists/${urlState.taskListID}/clear/?qID=${Date.now()}`,
          headers: "FYI: from showTaskLists2",
          body: putRESTReq
        })); 

      }
    })
    .then(resp => {
      putRESTResp = `${new Date().toISOString()}: update complete`;
      return(resp);
  }));
}

function initAddEntrySplash() {
  let result = loadLatest5DBRP()
  .then(resp => {
     return(load5DBRPTasks(resp));
  })
  .then(resp => {
    // the scheduled week is a JSON literal stored in the notes
    let dayData = calculateScheduledDay(JSON.parse(resp.notes));
    determineNextReading(dayData);
    // gapiLog.innerHTML += `${new Date().toISOString()}: dayData = ${JSON.stringify(dayData)}<br/>`;
    return(dayData);
  });  
  return(result);
}
// share with gapiInit.js script-mode code
window.initAddEntrySplash = initAddEntrySplash;

export { latest5DBRP, loadLatest5DBRP,
   scheduledWeek, load5DBRPTasks,
   scheduledDay, calculateScheduledDay,
   scheduledReading, determineNextReading,
   saveReading, putRESTReq, putRESTResp,
   weekTaskAdmin,
   updateNoteExtraDebug,
   initAddEntrySplash
};
