import m from 'mithril';

function getToken2() {
    // don't error check here, in contrast to getToken()
    return(new Promise((resolve, reject) => {
      try {
        // Settle this promise in the response callback for requestAccessToken()
        tokenClient.callback = (resp) => {
          if (resp.error !== undefined) {
            reject(resp);
          }
          // GIS has automatically updated gapi.client with the newly issued access token.
          console.log('gapi.client access token: ' + JSON.stringify(gapi.client.getToken()));
          resolve(resp);
        };
        tokenClient.requestAccessToken();
      } catch (err) {
        console.log(err)
      }
    }));
  }  
  
  function TasksAPITrampoline4(reqObj) {
    // see if I can make this trampoline shorter in code using refreshedReqObj2
    return(
      refreshedReqObj(reqObj).then(
        resp => m.request(resp)
      ).catch(
        err => {
          console.log(`TAT4 catch01: ${JSON.stringify(err)}`);
          gapi.client.setToken('');
          return(refreshedReqObj(reqObj).then(refreshedReqObj => {
            return(m.request(refreshedReqObj));
          }));
        }
      )
    );
  }
  
  function refreshedReqObj(reqObj) {
    // always set the headers before using a reqObj
    // I've been caught out using stale access_tokens before
    // could also make this a Promise-returning function
    // if we decide to call getToken2() in case gapi.client.getToken() is null
    if (gapi.client.getToken() == null) {
      return(
        getToken2().then(resp => {
          reqObj.headers = {
            Authorization: `Bearer ${gapi.client.getToken().access_token}`
          };
          return(reqObj);
        })
      );
    } else {
      return(new Promise((resolve, reject) => {
        reqObj.headers = {
          Authorization: `Bearer ${gapi.client.getToken().access_token}`
        };
        resolve(reqObj);
      }));
    }
  }
  
  export { TasksAPITrampoline4 };