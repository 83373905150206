import m from 'mithril';
import { preambleMC } from './preamble.js';
import { showTaskLists2, showTaskLists3 } from './adminHelpers.js';
import { add5DBRP5 } from './add5DBRPHelpers.js';
import { smlr } from './util.js';

// latest5DBRP and scheduledWeek are promise-driven variables
// executed from mountMithril() in view.js
// which is called by gapiInit.js after GAPI initialisation
import { latest5DBRP, scheduledWeek,
  scheduledDay, scheduledReading,
  saveReading, putRESTReq, putRESTResp,
  updateNoteExtraDebug,
  initAddEntrySplash
 } from './addEntryHelpers.js';
/*
import { latestReading } from './addEntryHelpers.js';
import { addReading } from './addEntryHelpers.js';
*/

let startTS = "";
let endTS = "";

function resetAllTS() {
  startTS = trimmedTS(new Date().toISOString());
  endTS = "";  
}

function setEndTS() {
  console.log(`setEndTS: ${endTS}`);
  endTS = trimmedTS(new Date().toISOString());
  console.log(`setEndTS: ${endTS}`);
}

resetAllTS();

function trimmedTS(iStr) {
  // console.log(`tTS: istr = ${iStr}`);
  let localTS = new Date(iStr).toLocaleString("sv");
  // console.log(`ttS: localTS = ${localTS}`)
  // return( iStr.slice(0, -5).replace("T", " "));
  if (localTS.localeCompare("Invalid Date") === 0) {
    return("");
  } else {
    return(localTS);
  }
}

let sdTableC = {
  view: function(vnode) {
    let defaultTC = m("table", {}, [
      m("tr", {}, [
        m("th", {}, "Reading"),
        m("th", {}, "Start TS"),
        m("th", {}, "End TS"),
      ]),
    ]);

    if (scheduledDay.length > 0) {
      let currentTC = m("table", {}, [
        m("tr", {}, [
          m("th", {}, "Reading"),
          m("th", {}, "Start TS"),
          m("th", {}, "End TS"),
        ]),
        scheduledDay[1].map(e => m("tr", {}, [
          m("td", {}, `${e.reading}`),
          m("td", {}, `${trimmedTS(e.startTS)}`),
          m("td", {}, `${trimmedTS(e.endTS)}`),        
        ])),
        /* m("tr", {}, [
          m("td", {}, "Gen 1"),
          m("td", {}, `${new Date().toDateString()}`),
          m("td", {}, `${new Date().toDateString() + 1}`),
        ]), */
      ]);  
      return(currentTC)
    } else {
      return(defaultTC);
    };
    
    // return(m("div", {}, "sdTableC MVP"));
  }
};

let addEntrySplash = {
  
  view: function(vnode) {

    let optionsMC = m("div", {}, [
      m("button", {onclick: function() {
        startTS = trimmedTS(new Date().toISOString());
        /* set start TS */return(false);
      }}, "start reading"),
      m("button", {onclick: function() {
        setEndTS();
      }}, "finish reading"),
      m("button", {onclick: function() {
        // set a sensible detault if end TS was omitted
        if (endTS.localeCompare("") === 0) {
          setEndTS();
        }
        saveReading({
          // use shorthand property names
          // because property names
          // and variable names
          // are the same
          startTS,
          endTS,
        }).then(resp => {
          resetAllTS();
          return(initAddEntrySplash());
        });
        return(true);
      }}, "update reading"),
    ]);
  
    /* loadLatest5DBRP().then(resp => {
      return(latestReading());
    }).then(resp => {
      // set reading parameter for display
    }); */
    let result = [
      m(preambleMC, vnode.attrs),
      m("div", {}, `Latest goal is ${(latest5DBRP.title == null) ? 
        "" : latest5DBRP.title}`),
      // m("div", {}, `scheduled week: ${JSON.stringify(scheduledWeek.title)}`),
      // m("div", {}, `scheduled day: ${JSON.stringify(scheduledDay)}`),
      m("div", {}, `${(scheduledWeek.title == null) ?
        "" : scheduledWeek.title
      } ${(scheduledDay == []) ?
        "" : scheduledDay[0]} readings: `),
      m(sdTableC),
      m("div", {}, `Next reading: ${scheduledReading}`),
      m("div", {}, `Start TS: ${startTS}`),
      m("div", {}, `Finish TS: ${endTS}`),
      m("div", {}, `PUT REST API request update status: ${(putRESTReq.updated == null) ?
        "" : JSON.stringify(putRESTReq.updated)
      }`),
      // m("div", {}, `updateNote() extra debug: ${JSON.stringify(updateNoteExtraDebug)}`),
      m("div", {}, `PUT REST API Results: ${JSON.stringify(putRESTResp)}`),
      optionsMC,
    ];
    
    return(result);
  }
};

// old way: export loadLatest5DBRP to window and call at end of gapiInit.js code
/* gapiLog.innerHTML += `${new Date().toISOString()}: before setting window fx <br/>`;
window.loadLatest5DBRP = loadLatest5DBRP;
gapiLog.innerHTML += `${new Date().toISOString()}: after setting window fx <br/>`; */

// new way: rely on window.mountMithril() late call dependency and move call to mountMithril function
export { addEntrySplash };