import m from 'mithril';
import { TasksAPITrampoline4 } from './apiHelpers.js';

// to my surprise, let resultsDOM = document.getElementById('apiResults')
// isn't usable within the splashHelper functions and I don't know why
// but implementing resultsDOM as a function works as desired
function resultsDOM() {
    return(document.getElementById('apiResults'));
}

function showTaskLists2() {
    // try with TasksAPITrampoline4
    TasksAPITrampoline4({
      method: "GET",
      url: "https://tasks.googleapis.com/tasks/v1/users/@me/lists",
      headers: "FYI: from showTaskLists2"
    }).then(APIResponse => {
      let taskListBits = {
        view: function(vnode) {
          return m("div",[
            m("ul", APIResponse
              .items
              .filter(e => e.title.includes("5DBRP"))
              .map(e => {
                // ORiGINAL return(m("li", `${e.etag}: ${e.title}`));
                return(m("li", {}, m("div", {}, [
                  [
                    m("div", `${e.etag}: ${e.title}`),
                    m("button", {
                      onclick: function() {
                        TasksAPITrampoline4({
                          method: "DELETE",
                          url: `https://tasks.googleapis.com/tasks/v1/users/@me/lists/${e.id}`,
                          headers: "FYI: ADDED IN callTasksAPI",
                          body: {
                            title: `5DBRP: ${new Date().toISOString()}`
                          }
                        }).then(resp => {
                          console.log(`DELETE RESPONSE: ${JSON.stringify(resp)}`);
                          showTaskLists2(); // refresh page
                        });
                      }
                    }, "Delete")
                  ]
                ])));
              }))
          ]);
        }
      };
      m.mount(resultsDOM(), taskListBits);
    });
  }
  
  function showTaskLists3() {
    // extract list element count
    // practice break the chain ref https://stackoverflow.com/a/28250704/15565204
    // reduce pyramid of doom
  
    let filteredLists = 
    TasksAPITrampoline4({
      method: "GET",
      url: "https://tasks.googleapis.com/tasks/v1/users/@me/lists",
      headers: "FYI: from showTaskLists2"
    }).then(APIResponse => APIResponse.items.filter(e => e.title.includes("5DBRP")));
  
    let listsData = filteredLists.then(resp => {    
      let result = resp.map(el => el.id);
      result = resp.map(el => 
        TasksAPITrampoline4({
          method: "GET",
          url: `https://tasks.googleapis.com/tasks/v1/lists/${el.id}/tasks`,
          headers: "FYI: ADDED IN callTasksAPI",
        })
      );
  
      console.log(`listsData resp: ${JSON.stringify(resp)}`);
      console.log(`listsData result: ${JSON.stringify(result)}`);
      return(Promise.all(result));
    });
  
    let listsDataPage2 = filteredLists.then(resp => {
      let result = resp.map(el => 
        TasksAPITrampoline4({
          method: "GET",
          // url: `https://tasks.googleapis.com/tasks/v1/lists/${el.id}/tasks?pageToken=${el.nextPageToken}`,
          url: `https://tasks.googleapis.com/tasks/v1/lists/${el.id}/tasks?maxResults=100`,
          headers: "FYI: ADDED IN callTasksAPI",
        })
      );
      return(Promise.all(result));
    });
  
    let generatedComponent = listsDataPage2.then(resp => {
      let mc01 = {
        view: function() {
          // SANITY CHECK LIST COUNT WORKS: return(m("div", {}, `ANYTHING?: ${JSON.stringify(resp.length)}`));
          return(m("ul", {}, resp.map((oneList, index) => m("li", {}, `${index}: ` +
            `item count = ${JSON.stringify(oneList.items.length)} ` +
            `next page token = ${oneList.nextPageToken}`))
         ));
        }
      };
      m.mount(resultsDOM(), mc01);    
      return(mc01);
    });
  
    Promise.all([filteredLists,
      listsData,
      listsDataPage2,  
      generatedComponent]).then(resp => {
        console.log(`sTL3 END0: ${JSON.stringify(resp[0])}`);
        console.log(`sTL3 END1: ${JSON.stringify(resp[1])}`);
        console.log(`sTL3 END2: ${JSON.stringify(resp[2])}`);
        console.log(`sTL3 END3: ${JSON.stringify(resp[3])}`);
    });
  }
  
export { showTaskLists2, showTaskLists3 };