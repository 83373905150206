import m from 'mithril';
import { preambleMC } from './preamble.js';
import { showTaskLists2, showTaskLists3 } from './adminHelpers.js';
import { add5DBRP5 } from './add5DBRPHelpers.js';

let adminSplash = {
    view: function(vnode) {
      var result = [
        m(preambleMC, vnode.attrs),
        m("button", {onclick: function() {add5DBRP5();}}, "Create 5DRBP-weeks tasklist"),
        m("button", {onclick: function() {showTaskLists2();}}, "5DBRP taskslists + delete options"),
        m("button", {onclick: function() {showTaskLists3();}}, "showcase: 5DBRP tasklist lengths"),
        m("div", {id: "apiResults"}, "API results here"),
      ];
      
      return(result);
    }
  };
  
  export { adminSplash };