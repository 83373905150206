// if you wrap m in curly braces, the code fails silently
// import m from 'mithril/mithril.min.js'; // this also works
// from https://mithril.js.org/installation.html
import m from 'mithril';
// require doesn't work either
// var m = require("mithril");
// import { customDate, customTime } from './util.js';

import { adminSplash } from './adminSplash.js';
import { addEntrySplash } from './addEntrySplash.js';
import { addEntrySplashBETA } from './addEntrySplashBETA.js';
import { initAddEntrySplash } from './addEntryHelpers.js';


function mountMithril() {
  var root = document.getElementById('mithrilSpace');
  m.route(root, `/add`, {
    "/admin": adminSplash,
    "/add": addEntrySplash,
    "/beta": addEntrySplashBETA,
  });
  // gapiLog.innerHTML += `${new Date().toISOString()}: about to call lL5DBRP from mountMithril<br/>`;
  return(initAddEntrySplash());
}

// send mountMithril function to the window
// and rely on window.mountMithril() late call dependency
// from gapiInit.js call after GAPI initialisation
gapiLog.innerHTML += `Build SHA: ${BUILD_REF} <br/>`;
gapiLog.innerHTML += `${new Date().toISOString()}: before mountMithril exported to window.mountMithril <br/>`;
window.mountMithril = mountMithril;
gapiLog.innerHTML += `${new Date().toISOString()}: after mountMithril exported to window.mountMithril <br/>`;

export { mountMithril };
